export interface IBannerPreviewProps {
  availableTerms: object;
}

export function BannerTermsList(props: IBannerPreviewProps) {
  return (
    <div className="terms">
      <h3>Clés de traduction</h3>
      {Object.keys(props.availableTerms).map((key) => (
        <div className="term" key={key}>
          <p className="termItem">
            {key}: {props.availableTerms[key]}
          </p>
        </div>
      ))}
    </div>
  );
}
