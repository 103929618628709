import React from "react";
import "./NoTextButton.scss";

interface NoTextButtonParams {
  icon: JSX.Element;
  color: string;
  diameter: string;
  click: () => any;
}

const NoTextButton = ({icon, color, diameter, click}: NoTextButtonParams) => {

  const iconContainerStyle = {
    width: diameter,
    height: diameter,
    backgroundColor: color,
  };

  return (
    <div className="divNoButton" style={iconContainerStyle} onClick={click} >
      <div className="buttonNoIcon">{icon}</div>
    </div>
  );
}

export default NoTextButton;