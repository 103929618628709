import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit'
import {
  contentsReducer,
  imagesReducer,
  translationsReducer,
} from './slices/hcms-slice';
import { authRecuder } from './slices/auth-slice';
import { allAcessReducer } from './slices/all-access-slice';

const rootReducer = combineReducers({
  auth: authRecuder,
  allAccess: allAcessReducer,
  hcmsContents: contentsReducer,
  hcmsImages: imagesReducer,
  hcmsTranslations: translationsReducer,
});
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']