export interface Language {
  ISOName: any;
  NativeName: any;
  Code: any;
}

export const IsoLanguages: Language[] = [
  {
    ISOName: 'Arabic',
    NativeName: 'العربية',
    Code: 'ar',
  },
  {
    ISOName: 'Azerbaijani',
    NativeName: 'azərbaycan dili',
    Code: 'az',
  },
  {
    ISOName: 'Bengali',
    NativeName: 'বাংলা',
    Code: 'bn',
  },
  {
    ISOName: 'Bulgarian',
    NativeName: 'български език',
    Code: 'bg',
  },
  {
    ISOName: 'Catalan',
    NativeName: 'català',
    Code: 'ca',
  },
  {
    ISOName: 'Chinese',
    NativeName: '中文(Zhōngwén),汉语,漢語',
    Code: 'zh',
  },
  {
    ISOName: 'Croatian',
    NativeName: 'hrvatski jezik',
    Code: 'hr',
  },
  {
    ISOName: 'Czech',
    NativeName: 'čeština',
    Code: 'cs',
  },
  {
    ISOName: 'Danish',
    NativeName: 'dansk',
    Code: 'da',
  },
  {
    ISOName: 'Dutch',
    NativeName: 'Nederlands',
    Code: 'nl',
  },
  {
    ISOName: 'English',
    NativeName: 'English',
    Code: 'en',
  },
  {
    ISOName: 'Esperanto',
    NativeName: 'Esperanto',
    Code: 'eo',
  },
  {
    ISOName: 'Estonian',
    NativeName: 'eesti',
    Code: 'et',
  },
  {
    ISOName: 'Finnish',
    NativeName: 'suomi',
    Code: 'fi',
  },
  {
    ISOName: 'French',
    NativeName: 'français',
    Code: 'fr',
  },
  {
    ISOName: 'Gaelic',
    NativeName: 'Gàidhlig',
    Code: 'gd',
  },
  {
    ISOName: 'Galician',
    NativeName: 'Galego',
    Code: 'gl',
  },
  {
    ISOName: 'German',
    NativeName: 'Deutsch',
    Code: 'de',
  },
  {
    ISOName: 'Greek',
    NativeName: 'ελληνικά',
    Code: 'el',
  },
  {
    ISOName: 'Hausa',
    NativeName: 'هَوُسَ',
    Code: 'ha',
  },
  {
    ISOName: 'Hebrew',
    NativeName: 'עברית',
    Code: 'he',
  },
  {
    ISOName: 'Hindi',
    NativeName: 'हिन्दी, हिंदी',
    Code: 'hi',
  },
  {
    ISOName: 'Hungarian',
    NativeName: 'magyar',
    Code: 'hu',
  },
  {
    ISOName: 'Indonesian',
    NativeName: 'Bahasa Indonesia',
    Code: 'id',
  },
  {
    ISOName: 'Irish',
    NativeName: 'Gaeilge',
    Code: 'ga',
  },
  {
    ISOName: 'Italian',
    NativeName: 'Italiano',
    Code: 'it',
  },
  {
    ISOName: 'Japanese',
    NativeName: '日本語(にほんご)',
    Code: 'ja',
  },
  {
    ISOName: 'Javanese',
    NativeName: 'ꦧꦱꦗꦮ, Basa Jawa',
    Code: 'jv',
  },
  {
    ISOName: 'Kazakh',
    NativeName: 'қазақ тілі',
    Code: 'kk',
  },
  {
    ISOName: 'Korean',
    NativeName: '한국어',
    Code: 'ko',
  },
  {
    ISOName: 'Latin',
    NativeName: 'latine, lingua latina',
    Code: 'la',
  },
  {
    ISOName: 'Latvian',
    NativeName: 'latviešu valoda',
    Code: 'lv',
  },
  {
    ISOName: 'Lithuanian',
    NativeName: 'lietuvių kalba',
    Code: 'lt',
  },
  {
    ISOName: 'Malay',
    NativeName: 'Bahasa Melayu,بهاس ملايو‎',
    Code: 'ms',
  },
  {
    ISOName: 'Malayalam',
    NativeName: 'മലയാളം',
    Code: 'ml',
  },
  {
    ISOName: 'Norwegian',
    NativeName: 'Norsk',
    Code: 'no',
  },
  {
    ISOName: 'Norwegian Bokmål',
    NativeName: 'Norsk Bokmål',
    Code: 'nb',
  },
  {
    ISOName: 'Persian',
    NativeName: 'فارسی',
    Code: 'fa',
  },
  {
    ISOName: 'Polish',
    NativeName: 'język polski, polszczyzna',
    Code: 'pl',
  },
  {
    ISOName: 'Portuguese',
    NativeName: 'Português',
    Code: 'pt',
  },
  {
    ISOName: 'Punjabi, Panjabi',
    NativeName: 'ਪੰਜਾਬੀ,پنجابی‎',
    Code: 'pa',
  },
  {
    ISOName: 'Quechua',
    NativeName: 'Runa Simi, Kichwa',
    Code: 'qu',
  },
  {
    ISOName: 'Romanian, Moldavian, Moldovan',
    NativeName: 'Română',
    Code: 'ro',
  },
  {
    ISOName: 'Russian',
    NativeName: 'русский',
    Code: 'ru',
  },
  {
    ISOName: 'Serbian',
    NativeName: 'српски језик',
    Code: 'sr',
  },
  {
    ISOName: 'Slovak',
    NativeName: 'Slovenčina, Slovenský Jazyk',
    Code: 'sk',
  },
  {
    ISOName: 'Slovenian',
    NativeName: 'Slovenski Jezik, Slovenščina',
    Code: 'sl',
  },
  {
    ISOName: 'Spanish',
    NativeName: 'Español',
    Code: 'es',
  },
  {
    ISOName: 'Swahili',
    NativeName: 'Kiswahili',
    Code: 'sw',
  },
  {
    ISOName: 'Swedish',
    NativeName: 'Svenska',
    Code: 'sv',
  },
  {
    ISOName: 'Tagalog',
    NativeName: 'Wikang Tagalog',
    Code: 'tl',
  },
  {
    ISOName: 'Tamil',
    NativeName: 'தமிழ்',
    Code: 'ta',
  },
  {
    ISOName: 'Telugu',
    NativeName: 'తెలుగు',
    Code: 'te',
  },
  {
    ISOName: 'Thai',
    NativeName: 'ไทย',
    Code: 'th',
  },
  {
    ISOName: 'Turkish',
    NativeName: 'Türkçe',
    Code: 'tr',
  },
  {
    ISOName: 'Ukrainian',
    NativeName: 'Українська',
    Code: 'uk',
  },
  {
    ISOName: 'Urdu',
    NativeName: 'اردو',
    Code: 'ur',
  },
  {
    ISOName: 'Vietnamese',
    NativeName: 'Tiếng Việt',
    Code: 'vi',
  },
  {
    ISOName: 'Welsh',
    NativeName: 'Cymraeg',
    Code: 'cy',
  },
];
