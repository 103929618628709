import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationFr from './assets/i18n/fr/fr.json';
import translationEn from './assets/i18n/en/en.json';
import translationEs from './assets/i18n/es/es.json';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

const isDevelopment = process.env.NODE_ENV === 'development';

// the translations
const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
  es: {
    translation: translationEs,
  },
};

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    //lng: 'en',
    debug: isDevelopment,
    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
  });

export default i18n;