import {
  AllAccess,
  AllAccessResponse,
  IAllAccessState,
} from '@models/AllAccess/all-access.model';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { SystemServiceHttp } from '@utils/http/http-common';
import { UTCDateToStringDate } from '@utils/date.util';

export const allAccessAdapter = createEntityAdapter<AllAccess>();
export const allAccessSelector = allAccessAdapter.getSelectors();

export const RetrieveAllAccess = createAsyncThunk<AllAccess>(
  'all-access/retrieve',
  async () => {
    const res = await SystemServiceHttp.get<AllAccessResponse>('/all-access');

    return {
      active: res.data.active,
      startDate: UTCDateToStringDate(res.data.startDate),
      endDate: UTCDateToStringDate(res.data.endDate),
      phasingOutDate: UTCDateToStringDate(res.data.phasingOutDate),
      updatedDate: UTCDateToStringDate(res.data.updatedDate),
      userName: res.data.userName,
    };
  }
);

export const UpdateAllAccess = createAsyncThunk<AllAccess, AllAccess>(
  'all-access/update',
  async (payload: AllAccess) => {
    await SystemServiceHttp.post<AllAccessResponse>('/all-access', payload);

    return payload;
  }
);

const initialState: IAllAccessState = {};

const allAccessSlice = createSlice({
  name: 'allAccess',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    function updateState(state, payload) {
      state.active = payload.active;
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
      state.phasingOutDate = payload.phasingOutDate;
      state.updatedDate = payload.updatedDate;
      state.userName = payload.userName;
    }
    builder.addCase(RetrieveAllAccess.fulfilled, (state, { payload }) => {
      updateState(state, payload);
    });
    builder.addCase(UpdateAllAccess.fulfilled, (state, { payload }) => {
      updateState(state, payload);
    });
  },
});

export const allAcessReducer = allAccessSlice.reducer;
