import MuiBreadcrumbs from "@components/Breadcrumbs/MuiBreadcrumbs";
import { PageRoute } from "../../models/page-route.enum";
import RegularButton from '@components/UI/RegularButton/RegularButton';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import NoTextButton from '@components/UI/NoTextButton/NoTextButton';
import Filter from "@components/SmartRelease/Filter/Filter";
import { useState } from "react";
import "./SmartUpdate.scss";
import { Link } from "react-router-dom";
import EditButton from "@components/EditButton/EditButton";
import { useTranslation } from 'react-i18next';

const SmartUpdate = () => {

  const {t} = useTranslation();

  const [filterActivated, setFilterActivated] = useState(false);
  const breadcrumbs =  new Map<string,PageRoute>([
    [`${t('home')}`, PageRoute.HOME],
    [`${t('smart-release.update-list')}`, PageRoute.UPDATE]
  ]);

  const rows = [
    { id:1, name: 'Mise à jour 1', date: '2022/10/01 - 15:59:00', version:'1.0.9' },
    { id:2, name: 'Mise à jour 2', date: '2022/10/01 - 15:59:00', version: '1.0.8' },
    { id:3, name: 'Mise à jour 3', date: '2022/10/01 - 15:59:00', version: '1.0.7' }
  ];
  const onClick = () =>{}
  const columns = [
    {field: "name", headerName: `${t('name')}`, width: 200},
    {field: "date", headerName: `${t('smart-release.date-added')}`, width: 200},
    {field: 'version', headerName: `${t('smart-release.version')}`, width: 125},
    {field: 'edit', headerName:'', width:140,
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
      return (
        <div style={{ cursor: "pointer"}}>
          <EditButton index={params.row.id} ev={onClick}/>
        </div>)
      }}
  ];

  const handleFilterClick = () => {
    setFilterActivated(!filterActivated);
    if (filterActivated) {
      document.getElementById("filter").classList.add("closed");

    } else {
      document.getElementById("filter").classList.remove("closed");
    }
  };

  return (<div role="presentation" className="updateHeader">
    <MuiBreadcrumbs routes={breadcrumbs}/>
    <div className="addUpdate">
      <div className="updateGrid" style={{ height: 500, width: "auto"}}>
        <div className="dataButtons">
          <Link to={`/${PageRoute.ADD_UPDATE}`}>
            <RegularButton icon={<AddIcon/>} color='#262626' height='43px' text={t('smart-release.add-update')}/>
          </Link>
          <NoTextButton icon={<FilterListIcon/>} color={`${!filterActivated ? '#3E4DDB' : '#262626'}`} diameter='42px' click={handleFilterClick}/>
        </div>
          <Filter/>
        <DataGrid className="grid" rows={rows} columns={columns} />
      </div>
    </div>

  </div>)
}

export default SmartUpdate;