import React, { useEffect } from 'react';
import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '@components/Layout/Layout';
import { PageRoute } from './models/page-route.enum';
import BannerList from './pages/Banner/List/BannerList';
import HomePage from '@pages/HomePage';
import SmartUpdate from '@pages/Update/SmartUpdate';
import AddReleasePage from '@pages/AddRelease/AddReleasePage';
import { BannerUpdate } from '@pages/Banner/Update/BannerUpdate';
import SignInPage from '@pages/Authentification/SignInPage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import BannerPage from '@pages/Banner/BannerPage';
import SessionHandler from '@components/SessionHandler/SessionHandler';
import AllAccessPage from '@pages/AllAccess/AllAccessPage';
import { isLoading, logout, refreshSession } from '@store/slices/auth-slice';
import { AddAxiosInterceptor } from '@utils/http/http-common';

const SignInRoutes = () => {
  return (
    <Routes>
      <Route path={PageRoute.SIGNIN} element={<SignInPage />} />
      <Route path="*" element={<Navigate to={PageRoute.SIGNIN} />} />
    </Routes>
  );
};

const HomeRoute = () => {
  return (
    <Routes>
      <Route path={PageRoute.HOME} element={<HomePage />} />
      <Route path={PageRoute.BANNERS} element={<BannerPage />}>
        <Route path="" element={<BannerList />} />
        <Route path={PageRoute.BANNER_UPDATE} element={<BannerUpdate />} />
      </Route>
      <Route path={PageRoute.UPDATE} element={<SmartUpdate />} />
      <Route path={PageRoute.ADD_UPDATE} element={<AddReleasePage />} />
      <Route path={PageRoute.ALL_ACCESS} element={<AllAccessPage />} />
      <Route path="/" element={<Navigate to={PageRoute.HOME} />} />
      <Route path="*" element={<Navigate to={PageRoute.HOME} />} />
    </Routes>
  );
};

const App = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const isSignedIn = state.auth.isLoggedIn;

  useEffect(() => {
    if (!state.auth.isLoggedIn) {
      dispatch(refreshSession());
    }
  }, [dispatch, state.auth.isLoggedIn]);

  useEffect(() => {
    if (state.auth.isLoggedIn) {
      AddAxiosInterceptor(state.auth);
    }
  }, [state.auth]);

  return (
    <>
      {!isLoading(state) && (
        <Layout isLoggedIn={isSignedIn}>
          {!isSignedIn ? (
            <SignInRoutes />
          ) : (
            <>
              <SessionHandler
                onKeepSessionAlive={() => dispatch(refreshSession())}
                onLogout={() => dispatch(logout())}
              />
              <HomeRoute />
            </>
          )}
        </Layout>
      )}
    </>
  );
};

export default App;
