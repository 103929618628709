import classes from './AddReleasePage.module.scss';
import { PageRoute } from '../../models/page-route.enum';
import { useCallback, useEffect, useState } from 'react';
import MuiBreadcrumbs from '@components/Breadcrumbs/MuiBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { SystemServiceHttp } from '@utils/http/http-common';
import AutoCompleteInput from '@components/AutoCompleteInput/AutoCompleteInput';
import { RegionResponse } from '@models/SystemConfigurationApi/region-response';
import { LocaleResponse } from '@models/SystemConfigurationApi/locale-response';
import SelectedDetails from '@components/SmartRelease/SelectedDetails';

interface ReleaseData {
  releaseName: string;
  regions: RegionResponse[];
  selectedRegions: string[];
  locales: LocaleResponse[];
  selectedLocales: string[];
}

const AddReleasePage = () => {
  const [releaseData, setReleaseData] = useState<ReleaseData>({
    releaseName: 'Mise à jour 1',
    regions: [],
    selectedRegions: [],
    locales: [],
    selectedLocales: [],
  });
  const [regionInput, setRegionInput] = useState('');
  const [localeInput, setLocaleInput] = useState('');
  const date = new Date();
  const { t } = useTranslation();

  const GetRegions = useCallback(async () => {
    const params = new URLSearchParams([['searchTerm', regionInput]]);

    const response = await SystemServiceHttp.get<RegionResponse[]>('/regions', {
      params,
    });

    return response;
  }, [regionInput]);

  const GetLocales = useCallback(async () => {
    const response = await SystemServiceHttp.get<LocaleResponse[]>('/locales');

    return response;
  }, []);

  useEffect(() => {
    GetRegions().then((regions) => {
      setReleaseData((data) => {
        return { ...data, regions: regions.data };
      });
    });

    GetLocales().then((locales) => {
      setReleaseData((data) => {
        return { ...data, locales: locales.data };
      });
    });
  }, [GetLocales, GetRegions]);

  const breadcrumbs = new Map<string, PageRoute>([
    [`${t('home')}`, PageRoute.HOME],
    [`${t('smart-release.update-list')}`, PageRoute.UPDATE],
    [releaseData.releaseName, PageRoute.ADD_UPDATE],
  ]);

  const changeReleaseName = (event) => {
    setReleaseData((prevData) => {
      return { ...prevData, releaseName: event.target.value };
    });
  };

  const regionChangeHandler = (event) => {
    const region = event.target.value;

    setRegionInput(region);
  };

  const regionClickHandler = (event) => {
    setReleaseData((prevData) => {
      return {
        ...prevData,
        selectedRegions: [...prevData.selectedRegions, event.target.value],
      };
    });
  };

  const removeSelectedRegion = (regionIndex) => {
    const regions = [...releaseData.selectedRegions];

    regions.splice(regionIndex, 1);

    setReleaseData((prevData) => {
      return {
        ...prevData,
        selectedRegions: [...regions],
      };
    });
  };

  const localeChangeHandler = (event) => {
    const locale = event.target.value;

    setLocaleInput(locale);
  };

  const localeClickHandler = (event) => {
    setReleaseData((prevData) => {
      return {
        ...prevData,
        selectedLocales: [...prevData.selectedLocales, event.target.value],
      };
    });
  };

  const removeSelectedLocale = (regionIndex) => {
    const locales = [...releaseData.selectedLocales];

    locales.splice(regionIndex, 1);

    setReleaseData((prevData) => {
      return {
        ...prevData,
        selectedLocales: [...locales],
      };
    });
  };

  const regions = releaseData.regions
    .filter((region) => !releaseData.selectedRegions.includes(region.name))
    .map((region) => {
      return {
        id: region.id,
        name: region.name,
      };
    });

  const locales = releaseData.locales
    .filter(
      (locale) =>
        !releaseData.selectedLocales.includes(locale.name) &&
        locale.name.includes(localeInput)
    )
    .map((locale) => {
      return {
        id: locale.code,
        name: locale.name,
      };
    });

  return (
    <div className={classes.addReleaseMain}>
      <MuiBreadcrumbs routes={breadcrumbs} />
      <div className={classes.generalInformation}>
        <p className={classes.title}>
          {t('smart-release.general-information')}
        </p>
        <div className={classes.informations}>
          <div className={classes.infoItem}>
            <label>{t('smart-release.update-name')}</label>
            <input
              type="text"
              placeholder={t('smart-release.insert-name')}
              onChange={changeReleaseName}
            />
          </div>
          <div className={classes.infoItem}>
            <label>{t('smart-release.date-added')}</label>
            <input type="date" />
          </div>
          <div className={classes.infoItem}>
            <label>{t('smart-release.update-created-date')}</label>
            <div className={classes.releaseDate}>
              {date.toISOString().replace('T', ' ').split('.')[0]}
            </div>
          </div>
          <div className={classes.infoItem}>
            <label>{t('smart-release.last-modified')}</label>
            <div className={classes.releaseDate}>
              {date.toISOString().replace('T', ' ').split('.')[0]}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.releaseDetails}>
        <p className={classes.title}>{t('smart-release.details')}</p>
        <div className={classes.checksum}>
          <label>{t('smart-release.checksum')}</label>
          <div className={classes.checksumInputs}>
            <input type="text" placeholder={`${t('select')}...`} />
            <input type="button" value={`${t('calculate')}`} />
          </div>
        </div>
        <div className={classes.regionLocal}>
          <div className={classes.autocomplete}>
            <AutoCompleteInput
              placeholder="Regions"
              style={{ width: '350px' }}
              onInput={regionChangeHandler}
              onItemClick={regionClickHandler}
              items={regions}
            />
            <SelectedDetails
              details={releaseData.selectedRegions}
              onRemoveSelected={(index) => removeSelectedRegion(index)}
            />
          </div>
          <div className={classes.autocomplete}>
            <AutoCompleteInput
              placeholder="Languages"
              style={{ width: '350px' }}
              onInput={localeChangeHandler}
              onItemClick={localeClickHandler}
              items={locales}
            />
            <SelectedDetails
              details={releaseData.selectedLocales}
              onRemoveSelected={(index) => removeSelectedLocale(index)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReleasePage;
