import { FormControlLabel, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface IEditButtonProps {
  index: number;
  ev: () => void;
}

const EditButton = ({index, ev} : IEditButtonProps) => {
  const handleEditClick = () => {
    // some action
  };
  return (
    <FormControlLabel
      control={
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={handleEditClick}
        >
          <EditIcon style={{ color: '#000'}} />
        </IconButton>
      }
      label=''/>
  );
};

export default EditButton;