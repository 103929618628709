import React from "react";
import DeleteButton from "@components/DeleteButton/DeleteButton";
import './HomePage.scss';
import placeHolderCard from '../assets/card-home-placeholder.svg';

import './HomePage.scss';

const HomePage = () => {
  return (
    <div className="home-page" data-testid="home-page">
      <div className="cards">
        <div className="card leftCard">
          <img src={placeHolderCard} alt="picture placeholder" />
          <DeleteButton />
        </div>
        <div className="card middleCard">
          <img src={placeHolderCard} alt="picture placeholder" />
          <DeleteButton />
        </div>
        <div className="card rightCard">
          <img src={placeHolderCard} alt="picture placeholder" />
          <DeleteButton />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
