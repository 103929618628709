import {
  FormControlLabel,
  Switch,
  Typography
} from "@mui/material";
import "./Filter.scss";
import MuiSelect from "@components/MuiSelect/MuiSelect";
import { useTranslation } from 'react-i18next';

const versions = [
  '1.0.9',
  '1.0.8',
  '1.0.7',
  '1.0.6',
  '1.0.5',
  '1.0.4',
  '1.0.3',
  '1.0.2',
  '1.0.1',
];

const Filter = () => {
  const {t} = useTranslation();
  return (
    <div id="filter" className="filter closed">
      <Typography className="filterTitle">{t('smart-release.search-filter')}</Typography>
      <div className="filterText">
        <div className="itemText">
          <label>{t('name')}</label>
          <input type="text" placeholder={t('smart-release.insert-name')} className="textBoxFilter"/>
        </div>
        <div className="itemText">
          <label>{t('smart-release.date-added')}</label>
          <input type="date" placeholder={t('smart-release.date-added')} className="textBoxFilter"/>
        </div>
        <div className="itemText">
          <label>{t('smart-release.version')}</label>
          <MuiSelect items={versions} defaultWidth={267} />
        </div>
      </div>
      <div className="displayUpdates">
        <label>{t('smart-release.show')}</label>
        <div className="displaySwitches">
          <FormControlLabel control={<Switch />} label={t('smart-release.not-posted-releases-filter')} />
          <FormControlLabel control={<Switch defaultChecked/>} label={t('smart-release.posted-releases-filter')} />
        </div>
      </div>
    </div>
  )
}

export default Filter;