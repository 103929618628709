import classes from './SidePanel.module.scss';
import homeIcon from '../../assets/home.svg';
import { Link } from 'react-router-dom';
import { PageRoute } from '../../models/page-route.enum';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SidePanel = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.navBar}>
      <ul>
        <li>
          <div className={classes.line}></div>
          <Typography className={classes.item}>
            <Link to={PageRoute.HOME}>
              <img src={homeIcon} alt="homeIcon" /> {t('home')}
            </Link>
          </Typography>
        </li>
        <li>
          <div className={classes.line}></div>
          <Typography className={classes.item}>
            <Link to={PageRoute.BANNERS}>{t('smart-release.banner')}</Link>
          </Typography>
        </li>
        <li>
          <div className={classes.line}></div>
          <Typography className={classes.item}>
            <Link to={PageRoute.ALL_ACCESS}>{t('all-access.title')}</Link>
          </Typography>
        </li>
        <li>
          <div className={classes.line}></div>
          <Typography className={classes.item}>
            <Link to="#">Lübrairie</Link>
          </Typography>
        </li>
        <li>
          <div className={classes.line}></div>
          <Typography className={classes.item}>
            <Link to={PageRoute.UPDATE}>
              {t('smart-release.updates.other')}
            </Link>
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default SidePanel;
