import React from "react";
import './DeleteButton.scss';

const DeleteButton = () => {
  const [deleteClass, setDeleteClass] = React.useState('');

  const handleClick = () => {
    if (deleteClass === '') {
      setDeleteClass('delete');
      setTimeout(() => setDeleteClass(''), 3200);
    }
  }

  return <button className={`button ${deleteClass}`} onClick={handleClick}>
    <div className="trash">
      <div className="top">
        <div className="paper"></div>
      </div>
      <div className="box"></div>
      <div className="check">
        <svg viewBox="0 0 8 6">
          <polyline points="1 3.4 2.71428571 5 7 1"></polyline>
        </svg>
      </div>
    </div>
    <span>Delete Item</span>
  </button>
};

export default DeleteButton;