import './RegularButton.scss';
import React from 'react';

interface RegularButtonParams {
  icon: JSX.Element;
  color: string;
  height: string;
  text: string;
  onClick?: (e) => void;
}

const RegularButton = ({
  icon,
  color,
  height,
  text,
  onClick,
}: RegularButtonParams) => {
  const buttonStyle = {
    height: height,
  };
  const textStyle = {
    backgroundColor: color,
    height: height,
  };

  return (
    <div
      className="divButton"
      style={buttonStyle}
      onClick={onClick ? onClick : () => {}}
    >
      <div className="buttonIcon">{icon}</div>
      <div className="buttonText" style={textStyle}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default RegularButton;
