import { useNavigate, useParams } from 'react-router-dom';
import {
  hcmsContentsSelector,
  hcmsImagesSelector,
  hcmsTranslationsSelector,
  updateHcms,
} from '@store/slices/hcms-slice';
import { useEffect, useState, Fragment } from 'react';
import cloneDeep from 'clone-deep';
import { IsoLanguages } from '@assets/i18n/iso-languages';
import AddIcon from '@mui/icons-material/Add';
import './BannerUpdate.scss';
import { HcmsContent, UpdateHcmsPayload } from '@models/hcms/hcms.models';
import MuiBreadcrumbs from '@components/Breadcrumbs/MuiBreadcrumbs';
import { PageRoute } from '@models/page-route.enum';
import OptionSelect from '@components/Forms/OptionSelect/OptionSelect';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { BannerUpdateState } from '@models/banner/banner-update';
import { useBannerContext } from '../BannerPage';
import BannerPreview from './Preview/BannerPreview';
import { BannerTermsList } from './TermsList/BannerTermsList';
import { FileInputButton } from '@UI/FileInputButton/FileInputButton';
import { GetS3TemporaryCredentials, PutS3ObjectPublic } from '@utils/s3';
import { toast } from 'react-toastify';
import { Constants } from '@utils/app-constants';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

export const BannerUpdate = () => {
  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'banners' });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contentsState = useAppSelector((state) => state.hcmsContents);
  const translationsState = useAppSelector((state) => state.hcmsTranslations);
  const imagesState = useAppSelector((state) => state.hcmsImages);
  const bannerContext = useBannerContext();

  const [bannerUpdateData, setUpdateBannerData] = useState<BannerUpdateState>({
    currentLang: 'en',
    availableLanguages: [],
    currentTitleTerm: '',
    currentDescriptionTerm: '',
    currentBannerImage: '',
    availableTerms: {},
    availableTermsOptions: [],
    availableImagesOptions: [],
    updatedBannerBackgroundFileData: null,
    updatedBannerBackgroundFileName: '',
    updatedBannerBackgroundKey: '',
    updatedBannerBackgroundUrl: null,
    hcmsContent: hcmsContentsSelector.selectById(contentsState, id),
    bannerItem: null,
  });

  useEffect(() => {
    setUpdateBannerData((prevData) => {
      return {
        ...prevData,
        hcmsContent: hcmsContentsSelector.selectById(contentsState, id),
      };
    });
  }, [bannerContext, contentsState, id]);

  useEffect(() => {
    const translations = hcmsTranslationsSelector.selectById(
      translationsState,
      bannerUpdateData.currentLang
    );
    if (translations) {
      const translationTerms = translations.terms;

      setUpdateBannerData((prevData) => {
        return {
          ...prevData,
          availableTerms: translationTerms,
          availableTermsOptions: [
            { label: 'Aucun texte', value: '' },
            ...Object.keys(translationTerms).map((key) => ({
              value: key,
              label: key,
            })),
          ],
        };
      });
    }
  }, [translationsState, bannerUpdateData.currentLang]);

  useEffect(() => {
    const allImages = hcmsImagesSelector.selectAll(imagesState);
    if (allImages) {
      const defaultOption = {
        label: t('no-selected-image'),
        value: '',
      };
      const allImagesOptions = allImages.map((image) => ({
        label: image.fileName,
        value: image.bucketKey,
      }));
      console.info('images', [defaultOption, ...allImagesOptions]);
      setUpdateBannerData((prevData) => {
        return {
          ...prevData,
          availableImagesOptions: [defaultOption, ...allImagesOptions],
        };
      });
    }
  }, [imagesState]);

  useEffect(() => {
    if (bannerUpdateData.hcmsContent) {
      const homeSection = bannerUpdateData.hcmsContent.sections.find(
        (section) => section.name === 'Home'
      );
      let bannerItem = null;
      let currentTitleTerm = '';
      let currentDescriptionTerm = '';
      let currentBannerImage = '';

      if (homeSection && homeSection.items) {
        bannerItem = homeSection.items.find((item) => item.type === 'Banner');

        if (bannerItem) {
          currentTitleTerm = bannerItem.data.title;
          currentDescriptionTerm = bannerItem.data.description;
          currentBannerImage = bannerItem.data.image;

          if (!currentBannerImage.startsWith(`http`)) {
            currentBannerImage = `${Constants.LU_ADMIN_DASHBOARD_PUBLIC_S3_URL}${currentBannerImage}`;
          }
        }
      }

      setUpdateBannerData((prevData) => {
        return {
          ...prevData,
          bannerItem: bannerItem,
          currentTitleTerm: currentTitleTerm,
          currentDescriptionTerm: currentDescriptionTerm,
          currentBannerImage: currentBannerImage,
          availableLanguages: bannerUpdateData.hcmsContent.translations
            .map((translation) => translation.code)
            .map((code) => {
              const lang = IsoLanguages.find((lang) => lang.Code === code);
              return { value: lang.Code, label: lang.ISOName };
            }),
        };
      });
    }
  }, [bannerUpdateData.hcmsContent]);

  const selectUploadFile = (files: FileList) => {
    const updatedFile = files ? files[0] : null;
    const updatedBannerBackgroundUrl = updatedFile
      ? URL.createObjectURL(updatedFile)
      : null;
    setUpdateBannerData((prevData) => {
      return {
        ...prevData,
        updatedBannerBackgroundFileData: updatedFile,
        updatedBannerBackgroundFileName: updatedFile.name,
        updatedBannerBackgroundUrl,
      };
    });
  };

  const clearSelectedUploadFile = () => {
    setUpdateBannerData((prevData) => {
      return {
        ...prevData,
        updatedBannerBackgroundFileData: null,
        updatedBannerBackgroundFileName: '',
        updatedBannerBackgroundUrl: null,
      };
    });
  };

  const onSelectExistingImage = (e) => {
    const image = hcmsImagesSelector.selectById(imagesState, e.target.value);
    setUpdateBannerData({
      ...bannerUpdateData,
      updatedBannerBackgroundFileData: null,
      updatedBannerBackgroundFileName: '',
      updatedBannerBackgroundKey: image ? image.bucketKey : '',
      updatedBannerBackgroundUrl: image ? image.url : null,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newBannerItem = cloneDeep(bannerUpdateData.bannerItem);
    const newContent = cloneDeep(bannerUpdateData.hcmsContent) as HcmsContent;
    const homeSectionIndex = newContent.sections.findIndex(
      (item) => item.name === 'Home'
    );
    const updatedHomeSection = newContent.sections[homeSectionIndex];
    const bannerItemIndex = updatedHomeSection.items.findIndex(
      (item) => item.type === 'Banner'
    );

    newBannerItem.data.title = bannerUpdateData.currentTitleTerm;
    newBannerItem.data.description = bannerUpdateData.currentDescriptionTerm;
    updatedHomeSection.items.splice(bannerItemIndex, 1, newBannerItem);
    newContent.sections.splice(homeSectionIndex, 1, updatedHomeSection);

    setUpdateBannerData((prevData) => {
      return {
        ...prevData,
        hcmsContent: newContent,
        bannerItem: newBannerItem,
      };
    });

    const newTranslations = newContent.translations.map((translation) => {
      const availableTranslations = hcmsTranslationsSelector.selectById(
        translationsState,
        translation.code
      );

      [
        bannerUpdateData.currentTitleTerm,
        bannerUpdateData.currentDescriptionTerm,
      ].forEach((key) => {
        if (
          !Object.keys(translation.terms).includes(key) &&
          Object.keys(availableTranslations.terms).includes(key)
        ) {
          translation.terms[key] = availableTranslations.terms[key];
        }
      });

      return translation;
    });

    try {
      if (bannerUpdateData.updatedBannerBackgroundKey) {
        newBannerItem.data.image = bannerUpdateData.updatedBannerBackgroundKey;
      } else if (bannerUpdateData.updatedBannerBackgroundFileData) {
        const fileData =
          await bannerUpdateData.updatedBannerBackgroundFileData.arrayBuffer();
        const creds = GetS3TemporaryCredentials();

        const fileName = bannerUpdateData.updatedBannerBackgroundFileName;
        const objectKey = `hcms/images/${fileName}`;
        await PutS3ObjectPublic(
          Constants.LU_ADMIN_DASHBOARD_PUBLIC_S3_BUCKET_NAME,
          objectKey,
          fileData,
          creds
        );

        newBannerItem.data.image = objectKey;
      }

      const payload: UpdateHcmsPayload = {
        filename: bannerUpdateData.hcmsContent.id,
        item: newBannerItem,
        translations: newTranslations,
      };

      const updateRes = await dispatch(updateHcms(payload));

      if ('error' in updateRes) {
        toast.error(t('error-update'));
      } else {
        toast.success(t('success-update'));
        navigate(`/${PageRoute.BANNERS}`);
      }
    } catch (error) {
      toast.error(t('error-update'));
    }
  };

  const breadcrumbs = new Map<string, PageRoute>([
    ['Acceuil', PageRoute.HOME],
    ['Liste des banières', PageRoute.BANNERS],
    [bannerUpdateData.hcmsContent?.id, PageRoute.BANNER_UPDATE],
  ]);

  return (
    <div className="bannerUpdate">
      <MuiBreadcrumbs routes={breadcrumbs} />
      {bannerUpdateData.hcmsContent && (
        <div className="bannerInformation">
          <p className="title">Détails</p>
          <div className="bannerUpdate">
            <div className="previewContainer">
              <BannerPreview
                imageSrc={
                  bannerUpdateData.updatedBannerBackgroundUrl ??
                  bannerUpdateData.currentBannerImage
                }
                titleText={
                  bannerUpdateData.availableTerms[
                    bannerUpdateData.currentTitleTerm
                  ]
                }
                descriptionText={
                  bannerUpdateData.availableTerms[
                    bannerUpdateData.currentDescriptionTerm
                  ]
                }
              />
            </div>

            <div className="selectBackgroundImage">
              {bannerUpdateData.updatedBannerBackgroundFileData === null && (
                <Fragment>
                  {t('select-existing-bg-image')}:
                  <OptionSelect
                    testId="imageSelect"
                    testIdOptions="imageOptions"
                    options={bannerUpdateData.availableImagesOptions}
                    value={bannerUpdateData.updatedBannerBackgroundKey}
                    onChange={onSelectExistingImage}
                  ></OptionSelect>
                </Fragment>
              )}
              {bannerUpdateData.updatedBannerBackgroundKey.length < 1 && (
                <Fragment>
                  {bannerUpdateData.updatedBannerBackgroundFileData && (
                    <div className="filesList">
                      <div className="fileItem">
                        <p className="fileName">
                          {bannerUpdateData.updatedBannerBackgroundFileName}
                        </p>
                        <button
                          onClick={clearSelectedUploadFile}
                          className="resetButton"
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  )}
                  <FileInputButton
                    icon={<AddIcon />}
                    color="#262626"
                    height="43px"
                    text={t('upload-bg-image')}
                    multiple={false}
                    accept={'.jpg,.png'}
                    onChange={selectUploadFile}
                  />
                </Fragment>
              )}
            </div>

            <form onSubmit={handleSubmit} className="bannerForm">
              <label className="">
                {t('langue-select')}:
                <OptionSelect
                  testId="languageSelect"
                  testIdOptions="languageOptions"
                  options={bannerUpdateData.availableLanguages}
                  value={bannerUpdateData.currentLang}
                  onChange={(e) =>
                    setUpdateBannerData({
                      ...bannerUpdateData,
                      currentLang: e.target.value,
                    })
                  }
                ></OptionSelect>
              </label>
              <label>
                {t('title-key-select')}:
                <OptionSelect
                  testId="titleSelect"
                  testIdOptions="titleOptions"
                  options={bannerUpdateData.availableTermsOptions}
                  value={bannerUpdateData.currentTitleTerm}
                  onChange={(e) =>
                    setUpdateBannerData((prevData) => {
                      return { ...prevData, currentTitleTerm: e.target.value };
                    })
                  }
                ></OptionSelect>
                <p data-testid="titleTermDisplay">
                  {
                    bannerUpdateData.availableTerms[
                      bannerUpdateData.currentTitleTerm
                    ]
                  }
                </p>
              </label>
              <label>
                {t('description-key-select')}:
                <OptionSelect
                  testId="descriptionSelect"
                  testIdOptions="descriptionOptions"
                  options={bannerUpdateData.availableTermsOptions}
                  value={bannerUpdateData.currentDescriptionTerm}
                  onChange={(e) =>
                    setUpdateBannerData((prevData) => {
                      return {
                        ...prevData,
                        currentDescriptionTerm: e.target.value,
                      };
                    })
                  }
                ></OptionSelect>
                <p data-testid="descriptionTermDisplay">
                  {
                    bannerUpdateData.availableTerms[
                      bannerUpdateData.currentDescriptionTerm
                    ]
                  }
                </p>
              </label>
              <input type="submit" value="Submit" data-testid="submitBtn" />
            </form>
            <BannerTermsList
              availableTerms={bannerUpdateData.availableTerms}
            ></BannerTermsList>
          </div>
        </div>
      )}
    </div>
  );
};
