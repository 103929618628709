import MainNavigation from '@components/MainNavigation/MainNavigation';
import SidePanel from '@components/SidePanel/SidePanel';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.scss';

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
  isLoggedIn: boolean;
}

const Layout = ({ children, isLoggedIn }: ILayoutProps) => {
  return (
    <div className="layout">
      <MainNavigation />
      <div className="layout__content">
        {isLoggedIn && <SidePanel />}
        <main>{children}</main>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Layout;
