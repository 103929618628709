import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

interface INewPasswordChallenge {
  onSubmitNewPassword: (newPassword: string) => void;
  passwordValidationSchema: yup.AnyObjectSchema;
}

const NewPasswordChallenge = ({
  onSubmitNewPassword,
  passwordValidationSchema,
}: INewPasswordChallenge) => {
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    isNewPasswordValid: true,
    confirmPassword: '',
    isSamePassword: true,
  });

  const isValid =
    passwordData.newPassword !== '' &&
    passwordData.confirmPassword !== '' &&
    passwordData.isNewPasswordValid &&
    passwordData.isSamePassword;

  const changeNewPasswordHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newPassword = event.target.value;
    const isNewPasswordValid = passwordValidationSchema.isValidSync({
      password: newPassword,
    });

    setPasswordData((prevValue) => {
      return { ...prevValue, newPassword, isNewPasswordValid };
    });
  };

  const changeConfirmNewPasswordHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const confirmPassword = event.target.value;
    const isSamePassword = confirmPassword === passwordData.newPassword;

    setPasswordData((prevValue) => {
      return { ...prevValue, confirmPassword, isSamePassword };
    });
  };

  const submitNewPasswordHandler = () => {
    onSubmitNewPassword(passwordData.newPassword);
  };

  return (
    <>
      <Typography variant="h3" data-testid="new_password_component">
        Set new password
      </Typography>
      <div className="credentials" data-testid="signInPage">
        <TextField
          fullWidth
          type="password"
          variant="outlined"
          label={
            passwordData.isNewPasswordValid
              ? 'New password'
              : 'Minimum 8 characters'
          }
          error={!passwordData.isNewPasswordValid}
          onChange={changeNewPasswordHandler}
        />
        <TextField
          fullWidth
          type="password"
          variant="outlined"
          label={
            passwordData.isSamePassword
              ? 'Confirm new password'
              : 'Passwords does not match'
          }
          error={!passwordData.isSamePassword}
          onChange={changeConfirmNewPasswordHandler}
        />
      </div>
      <div style={{ margin: '1rem auto' }}>
        <Button
          disabled={!isValid}
          color="primary"
          variant="contained"
          onClick={submitNewPasswordHandler}
        >
          Confirm new password
        </Button>
      </div>
    </>
  );
};

export default NewPasswordChallenge;
