import { FormControlLabel, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import './BannerList.scss';
import { useNavigate } from 'react-router-dom';
import { useBannerContext } from '../BannerPage';

const BannerListActions = (item) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/banners/${item.index.id}`);
  };
  return (
    <FormControlLabel
      control={
        <IconButton
          color="secondary"
          aria-label="Edit banner"
          onClick={handleEditClick}
        >
          <EditIcon style={{ color: '#000' }} />
        </IconButton>
      }
      label=""
    />
  );
};

const BannerList = () => {
  const bannerContext = useBannerContext();
  const contents = bannerContext.entities;

  const columns = [
    { field: 'id', headerName: 'Nom', width: 200 },
    {
      field: 'edit',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <BannerListActions index={params.row} />
          </div>
        );
      },
    },
  ];

  return (
    <div className="BannerPage" data-testid="BannerPage">
      <div className="bannerList">
        <DataGrid className="grid" rows={contents} columns={columns} />
      </div>
    </div>
  );
};

export default BannerList;
