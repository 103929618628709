import { useAppDispatch } from '@store/hooks';
import { refreshSession } from '@store/slices/auth-slice';
import { useEffect, useState } from 'react';

const useRefreshToken = (refreshTokenAtMs: number) => {
  const [currentMs, setCurrentMs] = useState(Date.now());
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(async () => {
      await dispatch(refreshSession());
      setCurrentMs(Date.now());
    }, refreshTokenAtMs);
  }, [dispatch, currentMs, refreshTokenAtMs]);
};

export default useRefreshToken;
