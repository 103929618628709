import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { Constants } from './app-constants';

export const GetS3TemporaryCredentials = (): AWS.Credentials => {
  const credentials = new AWS.Credentials(
    Constants.LU_ADMIN_DASHBOARD_S3_TEMPORARY_ACCESS_KEY,
    Constants.LU_ADMIN_DASHBOARD_S3_TEMPORARY_SECRET
  );
  return credentials;
};

export const GetS3Object = (
  bucketName: string,
  objectKey: string,
  credentials?: AWS.Credentials
): Promise<S3.Types.GetObjectOutput> => {
  return new Promise((resolve, reject) => {
    const s3 = new S3({
      apiVersion: '2006-03-01',
      credentials: credentials,
    });

    const params = {
      Bucket: bucketName,
      Key: objectKey,
    };

    s3.getObject(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
};

export const PutS3ObjectPublic = (
  bucketName: string,
  objectKey: string,
  objectData: string | ArrayBuffer,
  credentials: AWS.Credentials
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const s3 = new S3({
      apiVersion: '2006-03-01',
      credentials: credentials,
    });

    const params: S3.PutObjectRequest = {
      Bucket: bucketName,
      Key: objectKey,
      Body: objectData,
      ACL: 'public-read',
    };

    s3.putObject(params, function (err, success) {
      if (err) reject(err);
      else resolve(success);
    });
  });
};
