export class Constants {
  public static AWS_DEFAULT_REGION = 'us-east-1';

  public static LU_ADMIN_DASHBOARD_USER_POOL_ID =
    process.env.REACT_APP_COGNITO_USERPOOL_ID;
  public static LU_ADMIN_DASHBOARD_APP_CLIENT_ID =
    process.env.REACT_APP_COGNITO_CLIENT_ID;

  public static LU_ADMIN_DASHBOARD_S3_BUCKET_NAME =
    process.env.REACT_APP_S3_BUCKET_NAME;
  public static LU_ADMIN_DASHBOARD_PUBLIC_S3_BUCKET_NAME =
    process.env.REACT_APP_S3_PUBLIC_BUCKET_NAME;
  public static LU_ADMIN_DASHBOARD_PUBLIC_S3_URL =
    process.env.REACT_APP_S3_PUBLIC_URL;
  public static LU_ADMIN_DASHBOARD_S3_TEMPORARY_ACCESS_KEY =
    process.env.REACT_APP_S3_TEMPORARY_ACCESS_KEY;
  public static LU_ADMIN_DASHBOARD_S3_TEMPORARY_SECRET =
    process.env.REACT_APP_S3_TEMPORARY_SECRET;
  public static LU_ADMIN_DASHBOARD_API_ENDPOINT = process.env.REACT_APP_API_URL;
  public static LU_SYSTEM_CONFIGURATION_API_URL =
    process.env.REACT_APP_LU_SYSTEM_CONFIGURATION_API_URL;

  public static LU_ADMIN_DASHBOARD_S3_USERS_FOLDER_NAME = 'users';
  public static LU_ADMIN_DASHBOARD_S3_CMS_FOLDER_NAME = 'cms';
  public static LU_ADMIN_DASHBOARD_S3_CMS_THUMBNAILS_FOLDER_NAME = 'thumbnails';
  public static LU_ADMIN_DASHBOARD_S3_PROFILE_FOLDER_NAME = 'profile';
  public static LU_ADMIN_DASHBOARD_S3_PROFILE_PHOTO = 'photo';
  public static LU_ADMIN_DASHBOARD_S3_LIBRARY_FOLDER_NAME = 'library';
  public static LU_COMMUNITY_S3_MEDIA_FOLDER_NAME = 'media';
  public static LU_COMMUNITY_S3_MEDIA_TYPE_IMAGES = 'images';
  public static LU_COMMUNITY_S3_MEDIA_TYPE_VIDEOS = 'videos';
  public static LU_COMMUNITY_S3_MEDIA_TYPE_AUDIO = 'audio';

  public static SESSION_TIMER =
    Number.parseInt(process.env.REACT_APP_SESSION_TIMER) || 14 * 60 * 1000; // default to 15 minutes
  public static GetUserCmsFolder(userSub: string): string {
    return `${Constants.LU_ADMIN_DASHBOARD_S3_USERS_FOLDER_NAME}/${userSub}/${Constants.LU_ADMIN_DASHBOARD_S3_CMS_FOLDER_NAME}`;
  }
}
