import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from "@mui/material";
import { useState } from "react";
import "./MuiSelect.scss"

const getStyles = (name: string, personName: readonly string[], theme: Theme) =>{
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;

interface MuiSelectParams {
  items: string[];
  defaultWidth: number;
}

const MuiSelect = ({items, defaultWidth}: MuiSelectParams) => {
  const theme = useTheme();
  const [selectMuiItemsAndWidth, setSelectMuiItemsAndWidth] = useState<{items:string[], width:number}>({items: [], width: defaultWidth});

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: defaultWidth,
      },
    },
  };

  const handleSelectChange = (event: SelectChangeEvent<typeof selectMuiItemsAndWidth.items>) => {
    const {
      target: { value },
    } = event;

    setSelectMuiItemsAndWidth({
      items : typeof value === "string" ? value.split(",") : value,
      width: document.getElementById("filterSelect").offsetWidth
    });
  }

  return (
    <FormControl>
      <Select
        style={{width:defaultWidth}}
        size="small"
        id="filterSelect"
        multiple
        value={selectMuiItemsAndWidth.items}
        onChange={handleSelectChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}>
        {items.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, selectMuiItemsAndWidth.items, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MuiSelect;