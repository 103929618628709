export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectProps {
  value: string;
  onChange: (e: any) => void;
  testId: string;
  testIdOptions: string;
  options: ISelectOption[]
}

function OptionSelect({ value, onChange, testId, testIdOptions, options }:ISelectProps) {
  return (
    <select value={value} onChange={onChange ?? onChange} data-testid={testId}>
      {options && options.map(option => (
        <option value={option.value} key={option.value} data-testid={testIdOptions}>{option.label}</option>
      ))}
    </select>
  );
}

export default OptionSelect;