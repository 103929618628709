import { AuthStatus } from '@models/authentification/auth-status.enum';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { Constants } from './app-constants';

const AUTHENTENTIIFACTION_FLOW_TYPE = 'USER_PASSWORD_AUTH';

const userPoolID = Constants.LU_ADMIN_DASHBOARD_USER_POOL_ID || '';
const clientID = Constants.LU_ADMIN_DASHBOARD_APP_CLIENT_ID || '';
const storage =
  process.env.NODE_ENV !== 'production' ? localStorage : sessionStorage;

const poolData: ICognitoUserPoolData = {
  UserPoolId: userPoolID,
  ClientId: clientID,
  Storage: storage,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: CognitoUser = userPool.getCurrentUser();

const GetCognitoUser = (username: string): CognitoUser => {
  const userData = {
    Username: username,
    Pool: userPool,
    Storage: storage,
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType(AUTHENTENTIIFACTION_FLOW_TYPE);

  return cognitoUser;
};

export const ReturnUser = (): CognitoUser => {
  if (!currentUser) {
    return userPool.getCurrentUser();
  }
  return currentUser;
};

export const GetSession = async (): Promise<CognitoUserSession> => {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise((resolve, reject) => {
    if (currentUser) {
      currentUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    } else {
      reject('No current user');
    }
  });
};

export const RefreshSession = async (session): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    currentUser.refreshSession(session.getRefreshToken(), (err, session) => {
      if (err) {
        return reject(err);
      }
      if (!session) {
        return resolve(null);
      }

      currentUser.setSignInUserSession(session);
      return resolve(session);
    });
  });
};

export const Login = (
  username: string,
  password: string
): Promise<CognitoUserSession | AuthStatus> => {
  const authenticationData: IAuthenticationDetailsData = {
    Username: username,
    Password: password,
  };

  const authentificationDetails = new AuthenticationDetails(authenticationData);

  currentUser = GetCognitoUser(username);

  return new Promise((resovle, reject) => {
    currentUser.authenticateUser(authentificationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        return resovle(AuthStatus.NewPassword);
      },
      onSuccess: (session) => {
        currentUser.setSignInUserSession(session);
        return resovle(session);
      },
      onFailure: (error) => {
        return reject(error);
      },
    });
  });
};

export const ConfirmNewPassword = (
  newPassword: string
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    currentUser.completeNewPasswordChallenge(newPassword, [], {
      onSuccess: (session) => {
        return resolve(session);
      },
      onFailure: (error) => {
        return reject(error);
      },
    });
  });
};

export const Logout = async (): Promise<void> => {
  if (currentUser) {
    currentUser.signOut();
  }
};
