import React from 'react';
import './MainNavigation.scss';
import LogoutIcon from '@mui/icons-material/Logout';
import profile from '../../assets/icon_profile.svg';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { logout } from '@store/slices/auth-slice';

const MainNavigation = () => {
  const dispatch = useAppDispatch();
  const sessionInfo = useAppSelector((state) => state.auth.sessionInfo);
  const isSignedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header className="header">
      <div className="cheatDiv"></div>
      <nav>
        {isSignedIn ? (
          <div className="nav-items">
            <div className="email">{sessionInfo.email}</div>
            <div className="profilePic">
              <img src={profile} alt="profilePic" />
            </div>
            <div className="square">
              <LogoutIcon className="logoutIcon" onClick={logoutHandler} />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </nav>
    </header>
  );
};

export default MainNavigation;
