import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import './SignInPage.scss';
import NewPasswordChallenge from '@components/Authentification/NewPasswordChallenge';
import { PageRoute } from '@models/page-route.enum';
import { AuthStatus } from '@models/authentification/auth-status.enum';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { confirmNewPassword, login } from '@store/slices/auth-slice';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';

const LOGIN_ERROR_MESSAGE = 'Bad email or password!';
const emailValidationSceham = yup.object().shape({
  email: yup.string().email().required(),
});
const passwordValidationSchema = yup.object().shape({
  password: yup.string().min(8).required(),
});

interface SignInData {
  email: string;
  isEmailValid: boolean;
  password: string;
  isPasswordValid: boolean;
  error: string;
  newPasswordChallenge: boolean;
}

const SignInPage = () => {
  const [signInData, setSignInData] = useState<SignInData>({
    email: '',
    password: '',
    isEmailValid: true,
    isPasswordValid: true,
    error: '',
    newPasswordChallenge: false,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isValid =
    signInData.email !== '' &&
    signInData.isEmailValid &&
    signInData.password !== '' &&
    signInData.isPasswordValid;

  const emailChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const email = event.target.value;
    const isEmailValid = emailValidationSceham.isValidSync({ email });

    setSignInData((prevData) => {
      return {
        ...prevData,
        email,
        isEmailValid,
        error: '',
      };
    });
  };

  const passwordChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const password = event.target.value;
    const isPasswordValid = passwordValidationSchema.isValidSync({ password });

    setSignInData((prevData) => {
      return { ...prevData, password, isPasswordValid, error: '' };
    });
  };

  const signInHandler = async (event) => {
    event.preventDefault();

    const signInAction = await dispatch(
      login({
        username: signInData.email,
        password: signInData.password,
      })
    );

    if (isFulfilled(signInAction)) {
      if (signInAction.payload.status === AuthStatus.NewPassword) {
        setSignInData((prevData) => {
          return { ...prevData, newPasswordChallenge: true };
        });
      } else {
        return navigate(`/${PageRoute.HOME}`);
      }
    } else if (isRejected(signInAction)) {
      setSignInData((prevData) => {
        return { ...prevData, error: LOGIN_ERROR_MESSAGE };
      });
    }
  };

  const completeNewPasswordChallenge = (newPassword: string): void => {
    dispatch(confirmNewPassword(newPassword)).then(() => {
      return navigate(`/${PageRoute.HOME}`);
    });
  };

  if (signInData.newPasswordChallenge) {
    return (
      <Paper className="auth">
        <NewPasswordChallenge
          onSubmitNewPassword={completeNewPasswordChallenge}
          passwordValidationSchema={passwordValidationSchema}
        />
      </Paper>
    );
  }

  return (
    <Box component="form" onSubmit={signInHandler}>
      <Paper className="auth">
        <Typography variant="h3">Sign in</Typography>
        <div className="credentials" data-testid="signInPage">
          <TextField
            fullWidth
            variant="outlined"
            label={signInData.isEmailValid ? 'Email' : 'Invalid Email'}
            error={!signInData.isEmailValid}
            onChange={emailChangeHandler}
          />
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label={
              signInData.isPasswordValid ? 'password' : 'Minimum 8 characters'
            }
            error={!signInData.isPasswordValid}
            onChange={passwordChangeHandler}
          />
        </div>
        <Typography variant="body2">Forgot Password?</Typography>
        {signInData.error !== '' && (
          <Typography color="error" variant="body2">
            {signInData.error}
          </Typography>
        )}
        <div className="buttons">
          <Button
            type="submit"
            disabled={!isValid}
            color="primary"
            variant="contained"
          >
            Sign In
          </Button>
        </div>
        <Typography variant="body1">Register a new account</Typography>
      </Paper>
    </Box>
  );
};

export default SignInPage;
