import {
  HcmsContent,
  HcmsImage,
  HcmsResponse,
  HcmsTranslation,
  UpdateHcmsPayload,
} from '@models/hcms/hcms.models';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { SystemServiceHttp } from '@utils/http/http-common';
import { HcmsActions } from '@store/actions/hcms.actions';
import { AxiosResponse } from 'axios';

const ToContentEntity = (content: any): HcmsContent => {
  return {
    id: content.fileName,
    sections: content.sections,
    translations: content.translations,
  } as HcmsContent;
};

const ToTranslationEntity = (translation: any): HcmsTranslation => {
  return {
    id: translation.code,
    terms: translation.terms,
  } as HcmsTranslation;
};

export const hcmsContentsAdapter = createEntityAdapter<HcmsContent>();
export const hcmsContentsSelector = hcmsContentsAdapter.getSelectors();

export const hcmsImagesAdapter = createEntityAdapter<HcmsImage>();
export const hcmsImagesSelector = hcmsImagesAdapter.getSelectors();

export const hcmsTranslationsAdapter = createEntityAdapter<HcmsTranslation>();
export const hcmsTranslationsSelector = hcmsTranslationsAdapter.getSelectors();

export const retrieveHcms = createAsyncThunk<HcmsResponse>(
  HcmsActions.HCMS_RETRIEVE,
  async () => {
    const res = await SystemServiceHttp.get<HcmsResponse>('/hcms');

    return res.data;
  }
);

export const updateHcms = createAsyncThunk<HcmsContent, UpdateHcmsPayload>(
  HcmsActions.HCMS_UPDATE,
  async (payload: UpdateHcmsPayload) => {
    const res = await SystemServiceHttp.post<
      HcmsContent,
      AxiosResponse<HcmsContent, any>,
      UpdateHcmsPayload
    >('/hcms/banner', payload);

    return res.data;
  }
);

const hcmsContentsSlice = createSlice({
  name: 'hcmsContents',
  initialState: hcmsContentsAdapter.getInitialState(),
  reducers: {
    contentAdded(state, action: PayloadAction<HcmsContent>) {
      hcmsContentsAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveHcms.fulfilled, (state, { payload }) => {
      hcmsContentsAdapter.setAll(
        state,
        payload.contents.map((content) => ToContentEntity(content))
      );
    });
    builder.addCase(updateHcms.fulfilled, (state, action) => {
      hcmsContentsAdapter.setOne(state, ToContentEntity(action.payload));
    });
  },
});

const hcmsImagesSlice = createSlice({
  name: 'hcmsImages',
  initialState: hcmsImagesAdapter.getInitialState(),
  reducers: {
    imageAdded(state, action: PayloadAction<HcmsImage>) {
      hcmsImagesAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveHcms.fulfilled, (state, { payload }) => {
      const images = payload.images.map((image) => ({
        id: image.bucketKey,
        fileName: image.fileName,
        bucketKey: image.bucketKey,
        url: image.url,
      }));
      hcmsImagesAdapter.setAll(state, images);
    });
  },
});

const hcmsTanslationsSlice = createSlice({
  name: 'hcmsTranslations',
  initialState: hcmsTranslationsAdapter.getInitialState(),
  reducers: {
    translationAdded(state, action: PayloadAction<HcmsTranslation>) {
      hcmsTranslationsAdapter.addOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveHcms.fulfilled, (state, { payload }) => {
      hcmsTranslationsAdapter.setAll(
        state,
        payload.availableTranslations.map((translation) =>
          ToTranslationEntity(translation)
        )
      );
    });
  },
});

export const { contentAdded } = hcmsContentsSlice.actions;
export const { imageAdded } = hcmsImagesSlice.actions;
export const { translationAdded } = hcmsTanslationsSlice.actions;

export const imagesReducer = hcmsImagesSlice.reducer;
export const translationsReducer = hcmsTanslationsSlice.reducer;
export const contentsReducer = hcmsContentsSlice.reducer;

export default contentsReducer;
