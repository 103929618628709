import axios from 'axios';
import { Constants } from '@utils/app-constants';
import { IAuthState } from '@models/authentification/authentification-state';

export const SystemServiceHttp = axios.create({
  baseURL: Constants.LU_SYSTEM_CONFIGURATION_API_URL,
});

export function AddAxiosInterceptor(state: IAuthState) {
  if (state.sessionInfo?.accessToken) {
    SystemServiceHttp.defaults.headers.common = {
      Authorization: `Bearer ${state.sessionInfo.accessToken}`,
    };
  } else {
    SystemServiceHttp.defaults.headers.common = {};
  }
}
