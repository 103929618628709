import { AuthStatus } from '@models/authentification/auth-status.enum';
import { AuthentificationClaims } from '@models/authentification/authentification-claims';
import {
  IAuthState,
  SessionInfo,
} from '@models/authentification/authentification-state';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import * as cognito from '@utils/cognito';
import { RootState } from '@store/store';
import { AuthActions } from '@store/actions/auth.actions';

const initialState: IAuthState = {
  sessionInfo: {},
  isLoggedIn: false,
  status: AuthStatus.Loading,
};

const logoutState: IAuthState = {
  isLoggedIn: false,
  status: AuthStatus.SignedOut,
};

const RefreshSessionInfo = async (
  session: CognitoUserSession = null
): Promise<SessionInfo> => {
  return new Promise(async (resolve, reject) => {
    if (!session) {
      try {
        session = await cognito.GetSession();
      } catch {
        return resolve(null);
      }
    }

    const refreshedSession = await cognito.RefreshSession(session);

    const idTokenPayload: AuthentificationClaims = {
      ...refreshedSession.getIdToken().decodePayload(),
    };
    const sessionInfo: SessionInfo = {
      accessToken: idTokenPayload['custom:access_token'],
      refreshToken: refreshedSession.getRefreshToken().getToken(),
      email: idTokenPayload['email'],
      userId: idTokenPayload['sub'],
    };

    return resolve(sessionInfo);
  });
};

export const refreshSession = createAsyncThunk<IAuthState>(
  AuthActions.AUTH_REFRESH,
  async () => {
    const sessionInfo = await RefreshSessionInfo();

    if (!sessionInfo) {
      return logoutState;
    }

    return {
      sessionInfo: sessionInfo,
      isLoggedIn: true,
      status: AuthStatus.SignedIn,
    };
  }
);

export const login = createAsyncThunk<
  IAuthState,
  { username: string; password: string }
>(AuthActions.AUTH_LOGIN, async ({ username, password }) => {
  const userSession = await cognito.Login(username, password);
  if (userSession === AuthStatus.NewPassword) {
    return { status: AuthStatus.NewPassword, isLoggedIn: false };
  } else {
    const sessionInfo = await RefreshSessionInfo(
      userSession as CognitoUserSession
    );
    return {
      sessionInfo: sessionInfo,
      isLoggedIn: true,
      status: AuthStatus.SignedIn,
    };
  }
});

export const logout = createAsyncThunk(AuthActions.AUTH_LOGOUT, () => {
  cognito.Logout();
});

export const confirmNewPassword = createAsyncThunk<IAuthState, string>(
  AuthActions.AUTH_CONFIRM_NEW_PASSWORD,
  async (newPassword, { getState }) => {
    const state = getState() as RootState;
    if (state.auth.status === AuthStatus.NewPassword) {
      const session = await cognito.ConfirmNewPassword(newPassword);
      const sessionInfo = await RefreshSessionInfo(session);
      return {
        sessionInfo: sessionInfo,
        isLoggedIn: true,
        authStatus: AuthStatus.SignedIn,
      };
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sessionUpdated(state, action: PayloadAction<IAuthState>) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    const updateAuthPayload = (state, { payload }: { payload: IAuthState }) => {
      const updatedState = { ...state, ...payload };

      return updatedState;
    };

    builder.addCase(refreshSession.fulfilled, updateAuthPayload);
    builder.addCase(login.fulfilled, updateAuthPayload);

    builder.addCase(login.rejected, (state, action) => {
      state.status = AuthStatus.SignedOut;
      state.sessionInfo = null;
      state.isLoggedIn = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.status = AuthStatus.SignedOut;
      state.sessionInfo = null;
      state.isLoggedIn = false;
    });
    builder.addCase(confirmNewPassword.fulfilled, updateAuthPayload);
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const isLoading = createSelector(
  selectAuth,
  (state) => state.status === AuthStatus.Loading
);

export const { sessionUpdated } = authSlice.actions;

export const authRecuder = authSlice.reducer;
