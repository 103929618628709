import { AllAccess } from '@models/AllAccess/all-access.model';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material';
import {
  RetrieveAllAccess,
  UpdateAllAccess,
} from '@store/slices/all-access-slice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useTranslation } from 'react-i18next';

interface AllAccessData {
  allAccess: AllAccess;
  isError: boolean;
  message: string;
}

const AllAccessPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'all-access' });
  const [allAccessData, setAllAccessData] = useState<AllAccessData>({
    allAccess: null,
    isError: false,
    message: '',
  });

  const isLoading = !allAccessData.allAccess && !allAccessData.isError;
  const dispatch = useAppDispatch();
  const allAccessState = useAppSelector((state) => state.allAccess);

  const GetAllAccessData = useCallback(async () => {
    let allAccess = { ...allAccessState };
    if (Object.keys(allAccess).length === 0) {
      const value = await dispatch(RetrieveAllAccess());

      if (value.type === 'all-access/retrieve/rejected') {
        setAllAccessData({
          allAccess: null,
          isError: true,
          message: 'Error while getting data',
        });
        return;
      }

      allAccess = value.payload as AllAccess;
    }

    setAllAccessData({
      allAccess: {
        active: allAccess.active,
        startDate: allAccess.startDate,
        endDate: allAccess.endDate,
        phasingOutDate: allAccess.phasingOutDate,
        updatedDate: allAccess.updatedDate,
        userName: allAccess.userName,
      },
      isError: false,
      message: '',
    });
  }, [allAccessState, dispatch]);

  useEffect(() => {
    GetAllAccessData();
  }, [GetAllAccessData]);

  const changeActiveHandler = (event) => {
    setAllAccessData((prevData) => {
      return {
        ...prevData,
        allAccess: {
          ...prevData.allAccess,
          active: event.target.value === 'Active',
        },
        message: '',
      };
    });
  };

  const startDateHandler = (event) => {
    setAllAccessData((prevData) => {
      return {
        ...prevData,
        allAccess: { ...prevData.allAccess, startDate: event.target.value },
        message: '',
      };
    });
  };

  const endDateHandler = (event) => {
    setAllAccessData((prevData) => {
      return {
        ...prevData,
        allAccess: { ...prevData.allAccess, endDate: event.target.value },
        message: '',
      };
    });
  };

  const phasingOutDateHandler = (event) => {
    setAllAccessData((prevData) => {
      return {
        ...prevData,
        allAccess: {
          ...prevData.allAccess,
          phasingOutDate: event.target.value,
        },
        message: '',
      };
    });
  };

  const submitHandler = async () => {
    const result = await dispatch(UpdateAllAccess(allAccessData.allAccess));

    if (result.type !== 'all-access/update/fulfilled') {
      setAllAccessData((prevData) => {
        return { ...prevData, message: t('update-fail') };
      });
      return;
    }

    setAllAccessData((prevData) => {
      return { ...prevData, message: t('update-success') };
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ top: '50%', left: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem',
      }}
    >
      <h1>{t('title')}</h1>
      <p>
        {t('updated-on', {
          updatedDate: allAccessData.allAccess.updatedDate,
          username: allAccessData.allAccess.userName,
        })}
      </p>
      {allAccessData.message !== '' ? (
        <span style={{ fontWeight: 'bold', color: 'green' }}>
          {allAccessData.message}
        </span>
      ) : null}
      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'row' }}
        name="use-radio-group"
        defaultValue={allAccessData.allAccess.active ? 'Active' : 'Inactive'}
        onChange={changeActiveHandler}
      >
        <FormControlLabel
          value="Active"
          defaultValue={''}
          control={<Radio />}
          label="Active"
        />
        <FormControlLabel
          value="Inactive"
          defaultValue={''}
          control={<Radio />}
          label="Inactive"
        />
      </RadioGroup>
      <InputLabel htmlFor="startDate">{t('start-date')}</InputLabel>
      <TextField
        type="date"
        id="startDate"
        variant="outlined"
        sx={{ width: '200px' }}
        value={allAccessData.allAccess.startDate}
        onChange={startDateHandler}
      />

      <InputLabel htmlFor="endDate">{t('end-date')}</InputLabel>
      <TextField
        type="date"
        id="endDate"
        variant="outlined"
        sx={{ width: '200px' }}
        value={allAccessData.allAccess.endDate}
        onChange={endDateHandler}
      />

      <InputLabel htmlFor="phasingOutDate">{t('phasing-out-date')}</InputLabel>
      <TextField
        type="date"
        id="phasingOutDate"
        variant="outlined"
        sx={{ width: '200px' }}
        value={allAccessData.allAccess.phasingOutDate}
        onChange={phasingOutDateHandler}
      />
      <Button
        sx={{ alignSelf: 'flex-start', marginTop: '1rem' }}
        variant="contained"
        onClick={submitHandler}
      >
        {t('button-confirm-text')}
      </Button>
    </Box>
  );
};

export default AllAccessPage;
