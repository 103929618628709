import Delete from '@mui/icons-material/Delete';
import React from 'react';
import classes from './SelectedDetails.module.scss';

interface ISelectedDetail {
  details: string[];
  onRemoveSelected: (index) => void;
}

const SelectedDetails = ({ details, onRemoveSelected }: ISelectedDetail) => {
  const detailUI = details.map((detail, index) => {
    return (
      <li key={`${index}_${detail}`}>
        <div className={classes.selectedRegions}>
          <span style={{ width: '100%' }}>{detail}</span>
          <span
            data-testid={`delete__${detail}`}
            className={classes.deleteRegion}
            onClick={() => onRemoveSelected(index)}
          >
            <Delete />
          </span>
        </div>
      </li>
    );
  });

  return <ul className={classes.selected__list}>{detailUI}</ul>;
};

export default SelectedDetails;
