import React, { useEffect, useState } from 'react';
import { Input } from '@mui/material';
import classes from './AutoCompleteInput.module.scss';

type Props = {
  style?: {};
  placeholder: string;
  defaultValue?: string;
  items: {
    id: string;
    name: string;
  }[];
  onInput: (event) => void;
  onItemClick: (event) => void;
};

const AutoCompleteInput = ({
  style,
  placeholder,
  defaultValue,
  items,
  onInput,
  onItemClick,
}: Props) => {
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    if (inputFocus) {
      const handleClick = (event) => {
        if (event.target.id !== `autocomplete_${placeholder}_input`) {
          document.removeEventListener('click', handleClick);
          setInputFocus(false);
        }
      };

      document.addEventListener('click', handleClick);
    }
  }, [inputFocus, placeholder]);

  const clickHandler = (event) => {
    onItemClick(event);

    setInputFocus(false);
  };

  let autoCompleteItems = [];
  if (inputFocus) {
    autoCompleteItems = items.map((item) => {
      return (
        <input
          key={`${item.id}_${item.name}`}
          data-testid="autocomplete_item"
          id={`${placeholder}-index`}
          className={classes.autocompleteItems}
          value={item.name}
          onClick={clickHandler}
          onChange={() => {}}
        />
      );
    });
  }

  return (
    <div className={classes.autocomplete}>
      <Input
        id={`autocomplete_${placeholder}_input`}
        data-testid="input_field"
        type="text"
        sx={{ ...style }}
        defaultValue={defaultValue ?? ''}
        placeholder={placeholder}
        onInput={onInput}
        onFocus={() => {
          setInputFocus(true);
        }}
      />
      {inputFocus ? (
        <div
          data-testid="autocomplete_group"
          className={classes.autocompleteItemsGroup}
        >
          {autoCompleteItems}
        </div>
      ) : null}
    </div>
  );
};

export default AutoCompleteInput;
