import { Dialog } from '@mui/material';
import React from 'react';

interface IModal {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  open: boolean;
}

const ConfirmModal = ({ children, open, className = '' }: IModal) => {
  return (
    <Dialog open={open} className={className}>
      {children}
    </Dialog>
  );
};

export default ConfirmModal;
