import { Breadcrumbs, Link } from "@mui/material";
import { PageRoute } from "../../models/page-route.enum";
import {Link as RouterLink} from 'react-router-dom';
import './MuiBreadcrumbs.scss';

interface IBreadcrumbsProps {
  routes: Map<string, PageRoute>;
}

const MuiBreadcrumbs = ({routes}: IBreadcrumbsProps) => {
  const keys = Array.from(routes.keys());
  const links: JSX.Element[] = [];

  let index = 0;

  routes.forEach((value) => {
    if (index < routes.size - 1) {
      links.push(
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={`/${value}`}
          key={value}>
          {keys[index]}
        </Link>
      );
    }

    index++;
  });

  const currentPage = Array.from(routes.keys()).pop();

  return (
    <div className="breadcrumbsHeader">
      <div className="breadcrumbsItems">
        <Breadcrumbs aria-label="breadcrumb">
          {links}
          <span></span>
        </Breadcrumbs>
        <h3>{currentPage}</h3>
      </div>
    </div>
  )
}

export default MuiBreadcrumbs;