import './FileInputButton.scss';
import RegularButton from '@components/UI/RegularButton/RegularButton';
import React, { Fragment, useRef } from 'react';

export interface IFileInputButtonProps {
  icon: JSX.Element;
  color: string;
  height: string;
  text: string;
  multiple: boolean;
  accept: string;
  onChange: (files: FileList) => void;
}

export const FileInputButton = ({
  icon,
  color,
  height,
  text,
  multiple,
  accept,
  onChange,
}: IFileInputButtonProps) => {
  const inputRef = useRef(null);

  const inputFileChange = (e) => {
    var files = (e.target as HTMLInputElement).files;
    onChange(files);
  };

  const onClick = () => {
    inputRef.current.click();
  };

  return (
    <Fragment>
      <input
        onChange={inputFileChange}
        accept={accept}
        multiple={multiple}
        style={{ display: 'none' }}
        type="file"
        ref={inputRef}
        hidden
      />
      <RegularButton
        icon={icon}
        color={color}
        height={height}
        text={text}
        onClick={onClick}
      />
    </Fragment>
  );
};
