import { HcmsContent } from '@models/hcms/hcms.models';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { hcmsContentsSelector, retrieveHcms } from '@store/slices/hcms-slice';
import { useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

export type BannerContext = { entities: HcmsContent[] | null };

const BannerPage = () => {
  const dispatch = useAppDispatch();
  const hcmsContentsState = useAppSelector((state) => state.hcmsContents);
  const entities = hcmsContentsSelector.selectAll(hcmsContentsState);
  const context: BannerContext = {
    entities,
  };

  useEffect(() => {
    if (Object.keys(entities).length === 0) {
      dispatch(retrieveHcms());
    }
  }, [dispatch, entities]);

  return <Outlet context={context} />;
};

export function useBannerContext() {
  return useOutletContext<BannerContext>();
}

export default BannerPage;
