import ConfirmModal from '@components/UI/Modal/ConfirmModal';
import useRefreshToken from '@hooks/use-refresh-token';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Constants } from '@utils/app-constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

const TIMER_DEFAULT_VALUE = 60; // seconds
const REFRESH_TOKEN_AT = 15 * 60 * 1000; // 15 minutes
const STORAGE_KEY = 'session-handler.timerStart';

interface ISessionHandlerData {
  timer: number;
  showModal: boolean;
}

interface ISessionHandler {
  onKeepSessionAlive: () => void;
  onLogout: () => void;
  timeout?: number;
}

const SessionHandler = ({
  onKeepSessionAlive,
  onLogout,
  timeout,
}: ISessionHandler) => {
  useRefreshToken(REFRESH_TOKEN_AT);

  const { t } = useTranslation('translation', { keyPrefix: 'idle-timer' });
  const [sessionHandlerData, setSessionHandlerData] =
    useState<ISessionHandlerData>({
      showModal: false,
      timer: TIMER_DEFAULT_VALUE,
    });

  const onPromptAction = () => {
    localStorage.setItem(STORAGE_KEY, Date.now().toString());

    setSessionHandlerData((prev) => {
      return { ...prev, showModal: true };
    });
  };

  const logoutHandler = useCallback(() => {
    setSessionHandlerData({ showModal: false, timer: TIMER_DEFAULT_VALUE });
    localStorage.removeItem(STORAGE_KEY);

    onLogout();
  }, [onLogout]);

  const continueHandler = () => {
    onKeepSessionAlive();
    setSessionHandlerData({ showModal: false, timer: TIMER_DEFAULT_VALUE });
    localStorage.removeItem(STORAGE_KEY);

    idleTimer.start();
  };

  const idleTimer = useIdleTimer({
    startOnMount: true,
    promptTimeout: 1,
    timeout: timeout ? timeout : Constants.SESSION_TIMER,
    onPrompt: onPromptAction,
  });

  useEffect(() => {
    if (sessionHandlerData.showModal) {
      const currentTime = Date.now();
      const localStorageTime = Number.parseInt(
        localStorage.getItem(STORAGE_KEY) ?? '0'
      );

      if (currentTime - localStorageTime >= TIMER_DEFAULT_VALUE * 1000) {
        logoutHandler();
      }

      if (sessionHandlerData.timer > 0) {
        setTimeout(() => {
          setSessionHandlerData((prev) => {
            return { ...prev, timer: prev.timer - 1 };
          });
        }, 1000);
      } else if (sessionHandlerData.timer <= 0) {
        logoutHandler();
      }
    }
  }, [sessionHandlerData, logoutHandler]);

  return sessionHandlerData.showModal ? (
    <ConfirmModal open={true}>
      <DialogTitle
        data-testid="idle_modal"
        sx={{ backgroundColor: '#000000', color: '#FFFFFF' }}
      >
        {t('title')}
      </DialogTitle>
      <DialogContent
        sx={{
          width: '500px',
          backgroundColor: 'lightgrey',
        }}
      >
        <p>{t('response-time', { seconds: sessionHandlerData.timer })}</p>
        <p>{t('keep-alive-question')}</p>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          data-testid="button_logout"
          variant="outlined"
          onClick={logoutHandler}
        >
          {t('logout-button')}
        </Button>
        <Button
          data-testid="button_continue"
          variant="outlined"
          onClick={continueHandler}
        >
          {t('keep-alive-button')}
        </Button>
      </DialogActions>
    </ConfirmModal>
  ) : null;
};

export default SessionHandler;
