import './BannerPreview.scss';

export interface IBannerPreviewProps {
  imageSrc: string;
  titleText: string;
  descriptionText: string;
}

const BannerPreview = (props: IBannerPreviewProps) => {
  return (
    <div className="bannerPreview">
      <div className="previewTextContent">
        <div className="previewTitle" data-testid="titlePreview">
          {props.titleText}
        </div>
        <div className="previewDescription" data-testid="descriptionPreview">
          {props.descriptionText}
        </div>
      </div>
      <img
        className="previewImg"
        data-testid="previewImg"
        src={props.imageSrc}
        alt=""
      />
    </div>
  );
};

export default BannerPreview;
